<template>
  <tr>
    <td></td>
    <td class="justify-left">
      {{ log.id || '- - -' }}
    </td>
    <td class="justify-left">
      <span v-if="log.type" style="display: flex; justify-content: left; align-items: center; gap: 5px;">
        <v-icon :color="log.type.color">{{ log.type.icon }}</v-icon>
        <span> {{ log.type.name }}</span>
      </span>
    </td>
    <td class="justify-left">
      <span v-if="log.data_id">
        <span v-if="log.type">ID {{ log.type.name }}: </span>
        <strong> {{ log.data_id }}</strong>
      </span>
      <span v-else>- - -</span>
    </td>
    <td class="justify-left">
      <span v-if="log.action" style="display: flex; justify-content: left; align-items: center; gap: 5px;">
        <v-icon :color="log.action.color">{{ log.action.icon }}</v-icon>
        <span> {{ log.action.name }}</span>
      </span>
    </td>
    <td class="justify-left">
      <span v-if="log.origin" style="display: flex; justify-content: left; align-items: center; gap: 5px;">
        <v-icon :color="log.origin.color">{{ log.origin.icon }}</v-icon>
        <span> {{ log.origin.name }}</span>
      </span>
    </td>
    <td class="justify-left">
      {{ log.user_name || '- - -' }}
    </td>
    <td class="justify-left">
      {{ log.ip_address || '- - -' }}
    </td>
    <td class="justify-left">
      <span v-if="log.created_at">
        {{ log.created_at | moment('DD/MM/YY HH:mm:ss') }}
      </span>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'Logs',
    computed: {
      log () {
        return this.props.item
      }
    },
    props: {
      config: Object,
      props: Object,
      loading: Boolean
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>